import * as React from "react"
import styled from 'styled-components'
import Layout from "../layouts"
import Seo from "../components/seo"
import PrimaryButton from '../components/Button/PrimaryButton'
import { Link } from "gatsby"
import { Section, SectionPageTitle, SectionDescription, Container } from "../components/Section"
import { StaticImage } from "gatsby-plugin-image"

const SectionMedia = styled.div`
  position: relative;
  text-align: center;
`
const BgVectorGraphics = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity:0.15;
  .gatsby-image-wrapper{
    display: block;
  }
`
const BtnToolbar = styled.div`
  text-align:center;
  > a{
    display: inline-block;
  }
`

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="404: Not found" description="404: Not found" />    
      <Section xpt="370px" mpt="270px" pt="170px" xpb="280px" mpb="200px" pb="100px" bgColor="#F0F8FF">
        <BgVectorGraphics>
          <StaticImage
            src="../images/vecteezy-backgrond.jpg"
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="vecteezy-backgrond"
          />
        </BgVectorGraphics>
        <Container pl="3%" pr="3%" maxWidth="800px">
          <SectionMedia>
            <StaticImage
            src="../images/404.png"
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Banner"
          />
          </SectionMedia>
          <SectionPageTitle textAlign="center" mb="10px">Oops! Page Not Found</SectionPageTitle>            
          <SectionDescription textAlign="center"><p>No matter the size of the budget we can provide you the perfect business solution.</p></SectionDescription> 
          <BtnToolbar>
            <Link to="/"><PrimaryButton text="Go To Homepage" size="lg" /></Link>
          </BtnToolbar>                     
        </Container>
      </Section>
  </Layout>
)

export default NotFoundPage
